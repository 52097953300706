import payroll from '../../../assets/img/waka_payroll.png';
import logo from '../../../assets/img/waka_logo.png';
import waka_page1 from '../../../assets/img/waka_page1.png';
import waka_page2 from '../../../assets/img/waka_page2.png';
import waka_page3 from '../../../assets/img/waka_page3.png';
import waka_page4 from '../../../assets/img/waka_page4.png';

export const waka_payroll = payroll;
export const waka_logo = logo;

export const waka_pages = [
    waka_page1,
    waka_page2,
    waka_page3,
    waka_page4
]

export const waka_details = {
    key_features: [
        {
            title: 'Automated Payroll & Tax Management', 
            details: 'Streamlining payroll and tax processes.'
        },
        {
            title: 'Employee Management & Time Tracking', 
            details: 'Managing employee information and tracking work hours.'
        },
        {
            title: 'Reports, Analytics, & Compliance', 
            details: 'Generating reports, analyzing data, and ensuring security and regulatory compliance.'
        },
        {
            title: 'Bank Integration & Waka Wallet', 
            details: 'Integrating with banks and preparing for the upcoming Waka Wallet feature.'
        },
    ]
}