import csa_logo from '../../../assets/img/csa_logo.png';
import ez_oda from '../../../assets/img/ez_oda.png';
import waka_logo from '../../../assets/img/waka_logo.png';
import agri_logo from '../../../assets/img/AgriSense.png';
import bim_logo from '../../../assets/img/BIM_COM_LOGO.png';

export const logoData = [
    {
        id: 1,
        logo: bim_logo,
        width: '75px'
    },
    {
        id: 2,
        logo: waka_logo,
        width: '120px'
    },
    {
        id: 3,
        logo: agri_logo,
        width: '150px'
    },
    {
        id: 4,
        logo: ez_oda,
        width: '120px'
    },
    {
        id: 5,
        logo: csa_logo,
        width: '120px'
    }
]