import { Route, Routes, useNavigate } from 'react-router-dom';
import './global.js';
import { AboutUs, HomePage, WhyChooseUs } from './pages';
import { Main } from './layouts';

function App() {
  const navigate = useNavigate();
  return (
    <Routes navigate={navigate}>
      <Route path="/" element={<Main />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/WhyChooseUs" element={<WhyChooseUs/>}/>
        <Route path="/AboutUs" element={<AboutUs/>}/>
      </Route>
    </Routes>
  );
}

export default App;
