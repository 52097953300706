import {useState} from 'react';
import "./styles.scss";
import { Link, Typography } from '@mui/material';

const AppFeature = ({
  data = {
    icon: '',
    title: '',
    description: ''
  },
  style,
}) => {
  const [viewMore, setViewMore] = useState(false);

  return (
    <div className='AppFeature' style={style}>
     <div className='holder'>
        <img src={data.icon} alt="" className='icon' />
     </div>
     <div className='holder'>
      <Typography className="feature-title">{data.title}</Typography>
     </div>
     <div className='holder'>
      <Typography
      className="feature-description"
      display="inline"
      sx={!viewMore ? {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3
      } : null}>{data.description}</Typography>
      <div className='linkHolder'>
        {
          !viewMore ? <Link className='viewBtn' href='javaScript:void(0)' onClick={() => setViewMore(true)}>Show More</Link> : <Link className='viewBtn' href='javaScript:void(0)' onClick={() => setViewMore(false)}>Show Less</Link>
        }
      </div>
     </div>
    </div>
  )
}

export default AppFeature;