import * as React from "react";
import "./styles.scss";
import { Typography } from "@mui/material";

export default function WhyChooseUs() {

  return (
    <div id="aboutUs">
      <div className="inner-container">

          <Typography variant="h4" component="h2" className="contentTitle">About Us</Typography>
          <Typography variant="h6" component="p" className="content">Imagine a world where transformative software solutions go beyond mere automation and become catalysts for positive change. At BR&V Technology, we are driven by this vision, and our team of innovative thinkers is not afraid to dream big and take on extraordinary challenges.</Typography>
          <Typography variant="h6" component="p" className="content">We have developed software that has not only saved businesses significant amounts of money and improved efficiency but has also had a profound impact on saving lives. Our belief is that every project we undertake should be more than just a collection of features; it should be a powerful tool that addresses real problems and enhances people's lives.</Typography>
          <Typography variant="h6" component="p" className="content">Before diving into the development process, we take the time to truly understand your unique needs and goals. By doing so, we ensure that the custom software we create is precisely tailored to your business or organization, enabling you to unlock its full potential.</Typography>
          <Typography variant="h6" component="p" className="content">We are committed to building great software on solid foundations. To achieve this, we employ the latest technologies and development methodologies, ensuring that your custom software is not only scalable but also secure and reliable, providing a robust platform for your success.</Typography>
          <Typography variant="h6" component="p" className="content">As a small and agile company, we pride ourselves on our ability to be responsive to your needs. We are unafraid of tackling new challenges and going the extra mile to deliver exceptional results.</Typography>
          <Typography variant="h6" component="p" className="content">If you are searching for a custom software development company that can bring your ambitious vision to life, we are the team you can rely on. Our goal is to create software that truly changes the world for the better.</Typography>
          <Typography variant="h6" component="p" className="content">Contact us today to discover how we can help you turn your custom software ideas into reality. Together, we have the power to shape a future where software solutions revolutionize businesses, save millions, boost efficiency by 50%, and even save lives by reaching medical emergencies early. These examples are just a glimpse of what we can achieve, as our potential is only limited by our collective imagination.</Typography>
          <Typography variant="h6" component="p" className="content">If you have a groundbreaking idea for custom software that can make a lasting impact, we eagerly await hearing from you. Reach out to us today, and let's embark on a journey to change the world together.</Typography>
      </div>
    </div>
  )
}