import * as React from 'react';
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import { AppButton } from '../../Component';
import { menu } from '../menuData';
import { Button, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({
  onClickMenu,
}) => {

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const handleLink = (link) => {
    navigate(link);
  }
  

  return (
    <div id='header'>
      <div className="inner-container">
        <div className='logo'>
          <Link href='javaScript:void(0)' onClick={() => handleLink("./")}><img src="/logo_w.png" alt="" className='imgLogo' /></Link>
        </div>
        <div className='menu'>
          {
            menu.map((item) => {
              return (
                <Link key={item.link} href='javaScript:void(0)' onClick={() => handleLink(item.link)}>{item.title}</Link>
              )
            })
          }
        </div>
        <div className='contactus'>
          <AppButton title="Contact Us" onClick={()=> window.location.href = "#contactUs"}/>
        </div>
        <div className='mobile'>
          <Button onClick={() => onClickMenu()} variant="text"><MenuIcon size={21} color="#ffffff" /></Button>
        </div>
      </div>
    </div>
  )
}

export default Header;