import bim from '../../../assets/img/bim.png';

export const bim_logo = bim;

export const bim_details = {
    key_features: [
        {
            title: 'Monitoring & Security', 
            details: 'Observing systems and ensuring safety, including smoke detection and traffic control.'
        },
        {
            title: 'Energy Efficiency', 
            details: 'Implementing measures to save power and reduce carbon emissions.'
        },
        {
            title: 'Automation', 
            details: 'Reducing manpower by automating processes like parking management and maintenance tracking.'
        },
        {
            title: 'Carbon Management',
            details: 'Measuring and decreasing carbon emissions to promote environmental sustainability.'
        }
    ]
}