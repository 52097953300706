import * as React from 'react';
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const AppButton = ({
  title,
  style,
  containerStyle,
  variant = 1,
  onClick = () => {}
}) => {

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  

  return (
    <div className='AppButton' style={containerStyle}>
      <Button sx={style} className={variant === 1 ? 'contactus' : 'pricing'} onClick={() => onClick()} variant="contained">{title}</Button>
    </div>
  )
}

export default AppButton;