import ez_img from '../../../assets/img/ez_img.png';
import ez_oda from '../../../assets/img/ez_oda.png';
import ez_page1 from '../../../assets/img/ez_page1.png';
import ez_page2 from '../../../assets/img/ez_page2.png';
import ez_page3 from '../../../assets/img/ez_page3.png';
import ez_page4 from '../../../assets/img/ez_page4.png';

export const ez_img1 = ez_img;
export const ez_logo = ez_oda;

export const ez_pages = [
    ez_page1,
    ez_page2,
    ez_page3,
    ez_page4
]

export const ez_details = {
    key_features: [
        {
            title: 'Digital Menu & Contactless Ordering', 
            details: 'Displaying menus digitally and allowing customers to place orders without physical contact.'
        },
        {
            title: 'POS & KDS Integration', 
            details: 'Integrating with Point of Sale (POS) systems and Kitchen Display Systems (KDS) for seamless operations.'
        },
        {
            title: 'Order Customization & Tracking', 
            details: 'Enabling customers to customize orders and track them in real-time.'
        },
        {
            title: 'Contactless Payment & Alerts', 
            details: 'Offering contactless payment options and sending item preparation alerts and order status updates.'
        },
    ]
}