import * as React from "react";
import "./styles.scss";
import { Typography } from "@mui/material";

export default function WhyChooseUs() {

  return (
    <div id="whyChooseUs">
      <div className="inner-container">
          <Typography variant="h5" component="h2" className="contentTitle">Why Choose Us</Typography>
          <Typography variant="h6" component="p" className="content">Transform your business with BRV Technology Singapore, a boutique technology company based in Singapore. Our innovative solutions are designed to revolutionize the way you do business and propel you to new heights of success.</Typography>
          <Typography variant="h6" component="p" className="content">We have a strong presence in the Philippines and are currently engaged in ongoing projects with local municipal governments and major private companies, providing them with cutting-edge software solutions. Our expertise spans diverse industries such as agriculture, solar farms, water dams, and more.</Typography>
          <Typography variant="h6" component="p" className="content">When it comes to software development, we go above and beyond to create tailor-made solutions that cater to your specific needs and requirements. Say goodbye to manual tasks and hello to automation, efficient data management, and streamlined customer relations management. Our software solutions will supercharge your operations, saving you time, effort, and money.</Typography>
          <Typography variant="h6" component="p" className="content">But that's not all. Our web design services are second to none. With our expertise, we will craft visually stunning websites that not only capture the attention of your target audience but also ensure seamless navigation and user experience. Get ready to attract, engage, and convert more visitors into loyal customers, boosting your online presence and driving growth.</Typography>
          <Typography variant="h6" component="p" className="content">And let's not forget our trusted IT consulting services. Our team of experts will assess your existing IT infrastructure, identifying bottlenecks and areas for improvement. With our guidance, you can make informed decisions about your technology investments, optimizing your systems to its full potential and staying one step ahead of the competition.</Typography>
      </div>
    </div>
  )
}