import Telephone from '../assets/img/telephone.png';
import Mail from '../assets/img/mail.png';
import Pin from '../assets/img/pin.png';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { TfiLinkedin } from "react-icons/tfi";
import { AiFillInstagram } from "react-icons/ai";



export const menu = [
    {
        title: 'Why Choose Us',
        link: '/WhyChooseUs'
    },
    {
        title: 'How It Works',
        link: ''
    },
    {
        title: 'About Us',
        link: '/AboutUs'
    },
    // {
    //     title: 'Pricing',
    //     link: ''
    // }
]

export const menuFooter = [
    {
        icon: Telephone,
        title: 'Call Us',
        text: '+65 8205 1987',
        link: 'tel: +65 8205 1987'
    },
    {
        icon: Mail,
        title: 'Email Us',
        text: 'contact@brvtechnologysg.com',
        link: 'mailto:contact@brvtechnologysg.com'
    },
    // {
    //     icon: Pin,
    //     title: 'Address',
    //     text: 'Building Number 1, Singapore 102304',
    //     link: 'http://maps.google.com/?q=Building Number 1, Singapore 102304'
    // } // hide for now
]

export const footerSocialIcons = [
    {
        icon:  FaFacebookF,
        link: ''
    },
    {
        icon:  FaTwitter,
        link: ''
    },
    {
        icon:  TfiLinkedin,
        link: ''
    },
    {
        icon:  AiFillInstagram,
        link: ''
    },
]