import cyber_img from '../../../assets/img/cyber_img.png';

export const cyber_img1 = cyber_img;

export const cyber_details = {
    key_features: [
        {
            title: 'Expert Analysis',
            details: 'Our cybersecurity teams conduct a detailed assessment of your network, systems, and applications.'
        },
        {
            title: 'Risk Identification',
            details: 'We pinpoint potential security risks and provide actionable recommendations to mitigate them.'
        },
        {
            title: 'Compliance Check',
            details: 'Ensure your business meets industry standards and regulatory requirements.'
        },
        {
            title: 'Comprehensive Reporting',
            details: 'Receive a clear, concise report outlining our findings and prioritized action steps.'
        },
        {
            title: 'Ongoing Support',
            details: 'Benefit from our expert guidance and support to implement security measures and maintain a strong defense against cyber threats.'
        }
    ]
}