import agri_img from '../../../assets/img/agri_img.png';

export const agri_img1 = agri_img;

export const agri_details = {
    key_features: [
        {
            title: 'On-the-Spot Soil Analysis',
            details: 'Allows farmers to analyze soil immediately without the need to send samples to laboratories.'
        },
        {
            title: 'Accurate Decision-Making',
            details: 'Provides precise soil data on-site, enabling farmers to make informed decisions quickly'
        },
        {
            title: 'Comprehensive Reporting',
            details: 'Generates detailed reports after soil analysis, offering valuable insights for optimizing farming practices'
        }
    ]
}