import {useEffect, useState} from 'react';
import './style.scss';
import { Box, Drawer, Link, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { menu } from '../menuData';
import { useNavigate } from 'react-router-dom';
function Sidebar({
        display,
        setSideNav,
        handleClose = () => {}
}) {
    const navigate = useNavigate();
    const handleClickNav = (link) => {
        setSideNav(false)
        navigate(link)
    } 
    
    return (
        <Drawer
            id="drawer"
            anchor='right'
            open={display}
            onClose={handleClose}
        >
            <Box
                className="sideContent"
                role="presentation"
            >
                <div className="side-header">
                    <Link href="#" onClick={() => handleClose()}><CloseIcon /></Link>
                </div>
                <List>
                    {menu.map((item, index) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton onClick={() => handleClickNav(item.link)}>
                        <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    )
}

export default Sidebar;