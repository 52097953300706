import Mountain from "../../../assets/img/mountain.png"

export const features = [
    {
      title: 'Enterprise Systems',
      icon: Mountain,
      description: 'We understand the complexity and specific requirements of enterprise-level systems. Our team of skilled developers and strategists collaborates closely with your organization to design, develop, and implement robust and scalable enterprise solutions. From CRM and ERP systems to workflow automation and data management platforms, we empower businesses to streamline operations, enhance productivity, and achieve digital transformation.'
    },
    {
      title: 'Tech Startups',
      icon: Mountain,
      description: 'As a trusted partner for tech startups, we provide comprehensive services to help turn visionary ideas into successful digital products. From ideation and prototyping to MVP development and beyond, we assist startups in every stage of their journey. Our team possesses a deep understanding of the startup ecosystem, and we leverage agile methodologies and innovative technologies to build scalable and market-ready solutions that resonate with target audiences and attract investors.'
    },
    {
      title: 'Manpower Provision and Expert Hiring ',
      icon: Mountain,
      description: 'To support the growth and development of your enterprise or startup, we offer staff augmentation services and expert hiring. Whether you need additional developers, designers, project managers, or other technical resources, we provide skilled professionals to seamlessly integrate with your team. This flexible approach allows you to scale your workforce as needed, ensuring that you have the right expertise to drive your projects forward.'
    },
    {
      title: 'Drone Services',
      icon: Mountain,
      description: `We have an emerging presence in the Philippines and are currently engaged in ongoing projects with local municipal governments and major private companies. Our expertise spans diverse industries such as agriculture, solar farms, water dams, and more.`
    }
]

export const testimonial_data = [
    {
      avatar: '',
      name: 'John Doe',
      jobtitle: 'Ceo Founder',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.'
    },
    {
      avatar: '',
      name: 'Sheldon Cooper',
      jobtitle: 'Ceo Founder',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.'
    },
    {
      avatar: 'Missy Jones',
      name: 'Ceo Founder',
      jobtitle: '',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.'
    },
    {
      avatar: '',
      name: 'John Doe',
      jobtitle: 'Ceo Founder',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.'
    },
    {
      avatar: '',
      name: 'Sheldon Cooper',
      jobtitle: 'Ceo Founder',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.'
    },
    {
      avatar: '',
      name: 'Missy Jones',
      jobtitle: 'Ceo Founder',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.'
    },
  ]