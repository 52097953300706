import {useEffect, useState} from 'react';
import './style.scss';
import {  Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';

function Main() {
    const [sideNav, setSideNav] = useState(false);

    const handleClose = () => {
        setSideNav(false)
    }

    const handleMenu = () => {
        setSideNav(true)
    }
    
    return (
        <>
            <Header onClickMenu={() => handleMenu()} />
            <Sidebar display={sideNav} handleClose={() => handleClose()} setSideNav={setSideNav}/>
            <div className='globalPageContainer'>
                <Outlet />
            </div>
            <Footer/>
        </>
    )
}

export default Main;