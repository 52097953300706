import * as React from 'react';
import "./styles.scss";
import { Button, Link, Typography } from '@mui/material';

import {AppButton} from '../../Component';
import { footerSocialIcons, menuFooter } from '../menuData';

const Footer = (props) => {

  const handleFooterMenu = (item) => {
    if(item.title !== "Address"){
      window.location.href = item.link;
    }else{
      window.open(item.link, "_blank");
    }
  }

  return (
    <div id="footer">
      <div className="inner-container">
        <div className="getInTouch">
          <div className="overlay"></div>
          <div className="content">
            {/* <Typography className='subText'>Lorem ipsum dolor sit amet</Typography> */}
            <Typography className='mainText1'><span>At BRV Technology</span>, we are committed to delivering exceptional services that align with the specific needs and goals of enterprise systems and tech startups. With our expertise in developing enterprise solutions and supporting startups in their digital journeys, we help businesses harness the power of technology to drive innovation, accelerate growth, and achieve long-term success.</Typography>
            <Typography className='mainText2'>Partner with us to leverage our knowledge, experience, and technical prowess, as we collaborate to build transformative solutions for your enterprise systems
            or tech startup. Together, we can navigate the complexities of the digital landscape and create impactful solutions that propel your business forward.</Typography>
          </div>
          <div className="buttons">
            {/* <AppButton variant={2} onClick={() => {}} containerStyle={{marginRight: 10}} title="View pricing"/> */}
            {/* <AppButton variant={1} onClick={() => {}} title="Contact Us"/> */}
          </div>
        </div>
        <div id="contactUs" className="footerMenu">
          {
            menuFooter.map((item) => {
              return(
                <div className="menuHolder">
                  <img src={item.icon} alt="test" className='menu-icon' />
                  <div className='menu-content'>
                    <Typography className='menu-name'>{item.title}</Typography>
                    <Link className='menu-link' href="JavaScript:void(0)" onClick={() => handleFooterMenu(item)} variant="body2">
                      {item.text}
                    </Link>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="copy-right">
          <Typography className="content">Copyright © All Rights Reserved 2024  <span>BR&V TECHNOLOGY PTE. LTD.</span></Typography>
          <div className='social-icons'>
           {
            footerSocialIcons.map((item) => {
              return (
                <Button className='social-link' href={item.link} variant="text">
                  <item.icon />
                </Button>
              )
            })
           }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;